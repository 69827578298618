import React from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

// img
import Logo from '../../images/logo.png';
import LogoSm from '../../images/logo-sm-dark.png';

export default function TopMenu() {
    const location = useLocation();
    return (
        <>
            <Navbar expand="lg" className="top-menu">
                <Container>
                    <div className='leftbar-logo'>
                        <Link to='/dashboard' >
                            <img src={Logo} alt="" className='d-sm-block d-none' height={34} />
                            <img src={LogoSm} alt="" className='d-sm-none d-block' height={34} />
                        </Link>
                    </div>
                    <div className='topbar-user d-flex align-items-center d-lg-none ms-auto'>
                        <Link to="" className='nav-link me-3'>
                            <i className="bi bi-bell-fill fs-24"></i>
                        </Link>
                        <Dropdown>
                            <Dropdown.Toggle variant="p-0" id="top-user-sm">
                                <div className='d-flex align-items-center'>
                                    <i className="bi bi-person-fill fs-26"></i>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end" title="Dropdown end">
                                <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Navbar.Toggle className='ms-3' aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mx-auto">
                            <Link to='/dashboard' className={`nav-link ${location.pathname === '/dashboard' ? 'active' : ''}`}>Dashboard</Link>
                            <Link to='/mapping_assignment' className={`nav-link ${location.pathname === '/mapping_assignment' ? 'active' : ''}`}>Mapping & Assignment</Link>
                            <Link to='/management' className={`nav-link ${location.pathname === '/management' ? 'active' : ''}`}>Management</Link>
                        </Nav>
                        <Nav className="top-right-navbar topbar-user topbar-user d-none d-lg-flex align-items-center">
                            <Link to="" className='nav-link'>
                                <i className="bi bi-bell-fill"></i>
                            </Link>
                            <Dropdown>
                                <Dropdown.Toggle variant="p-0" id="top-user-lg">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-person-fill fs-26"></i> <h5 className='fs-20 d-none d-xl-block ms-2 mb-0'>Daniel Montano</h5>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" title="Dropdown end">
                                    <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                        {/* <div className='topbar-user d-none d-lg-block'>
                            <Dropdown>
                                <Dropdown.Toggle variant="p-0" id="top-user-lg">
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-person-fill fs-26"></i> <h5 className='fs-20 ms-2 mb-0'>Daniel Montano</h5>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="end" title="Dropdown end">
                                    <Link to='/' className='dropdown-item'><i className="bi bi-box-arrow-right fs-18 me-2"></i> Logout</Link>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}
