import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { getFromAPI, postToAPI,putToAPI, deleteFromAPI } from '../Utils/utils';
import { compileString } from 'sass';
import { toast,ToastContainer } from "react-toastify";

export default function Barcodes({ barcodeData }) {
    // Modal
    const [showBarcodeModal, setshowBarcodeModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setshowDeleteModal] = useState(false);
    const [barCodeInputData,setBarCodeInputData] = useState({
        code : '',
        code_type : '',
        isActive : false,
    });
  
    const[barCodeToEdit,setBarCodeToEdit] = useState(null);
    const [barCodeToDelete,setBarCodeToDelete] = useState(null);
    const [barCodeData,setBarcodeData] = useState([]);

    const fetchBarCodeData = () => {
        getFromAPI('QRCode/').then(data => {
            if (data) {
                setBarcodeData(data);
            } else {
                console.error('Unexpected data structure:', data);
            }
        }).catch(error =>{
            console.error('Error fetching barcodes:', error);
        });
    };

    useEffect(() => {
        fetchBarCodeData();
    }, []);



    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if(barCodeData.length > 0) {
            const tableElement = $("#barcodeDataTable");
        
        if($.fn.dataTable.isDataTable(tableElement))
        {
            tableElement.DataTable().clear().destroy();
        }

        tableElement.DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        }

                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        }
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print',
                        exportOptions: {
                            columns: ':not(:last-child)' // Exclude the last column ("Action")
                        }
                    }
                ],
                data: barCodeData.map((data, i) => [
                    i + 1,
                    data.code,
                    data.code_type,
                    `
                    <div class="d-flex">
                        <button class="btn btn-soft-primary table-btn me-2 edit-barcode" data-dest-id="${data.id}">
                            <i class="bi bi-pencil-square"></i>
                        </button>
                        <button class="btn btn-soft-danger table-btn delete-barcode" data-dest-id="${data.id}">
                            <i class="bi bi-trash3"></i>
                        </button>
                    </div>
                `
                ]),
                columns: [
                    { title: "No." },
                    { title: "QR/Barcode Code" },
                    { title: "Type" },
                    {title: "Action"}
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });
            // Event delegation to handle delete action
            tableElement.on('click', '.edit-barcode', function () {
                const barCodeId = $(this).data('dest-id');
                handleshowBarcodeModal("Edit BarCode", barCodeId, true);
            });

            // Handle delete button click
            tableElement.on('click', '.delete-barcode', function () {
                const barCodeId = $(this).data('dest-id');
                setBarCodeToDelete(barCodeId);
                setshowDeleteModal(true);
            });
        }
    }, [barCodeData]);

    const handleshowBarcodeModal = (title, barCode_id, isEdit = false) => {
        setModalTitle(title);
        setshowBarcodeModal(true);
        
        if(isEdit && barCode_id)
        {
            setBarCodeToEdit(barCode_id);
            getFromAPI(`QRCode/`).then(data => {
                if (Array.isArray(data)){
                    const barCodeData = data.find(barcode => barcode.id === barCode_id); 

                    if(barCodeData){
                        setBarCodeInputData({
                            code : barCodeData.code|| '',
                            code_type : barCodeData.code_type|| '',
                            isActive : barCodeData.isActive|| '',
                        });
                     } else{
                        console.error('No barcode found with id:', barCode_id);
                    }

                }
                else{
                    console.error('Unexpected data structure:', data);
                }
            }).catch(error => {
                console.error('Error fetching barcode data:', error);
            });
        }
        else{
            setBarCodeToEdit(null);
            setBarCodeInputData({
                code : "",
                code_type : "",
                isActive :false,
            });
        }

    };

    const handleCloseModal = () => {
        setshowBarcodeModal(false);
    };
  
    const handleSaveBarcode = async() => {
        if(!barCodeInputData.code || !barCodeInputData.code_type)
        {
            alert("Please fill in all fields");
            return;
        }
     
        try {
            let response;
            if(barCodeToEdit){
                response = await putToAPI(`QRCode/${barCodeToEdit}/`, barCodeInputData);
                if(response.status){
                    toast.success('Barcode Updated successfully','success');
                    fetchBarCodeData();
                }
                else {
                    toast.error('Failed to update Barcode','error');

                }
              }  else{
                    response = await postToAPI("QRCode/",barCodeInputData);
                    if(response.status){
                        toast.success('Barcode added successfully','success');
                        fetchBarCodeData();
                    }
                    else {
                        toast.error('Failed to Add Barcode','error');
                    }
            }
            handleCloseModal();
        } catch (error) {
            console.error('Error saving barcode:', error);
            toast.error('Error Saving barcode','error');

        }
    };

    //Confirm Delete
    const handeleConfirmDelete = async() => {
        try {
            await deleteFromAPI(`QRCode/${barCodeToDelete}/`);
            toast.success('Barcode deleted successfully','success');
            setshowDeleteModal(false);
            fetchBarCodeData();
        }
        catch (error) {
            console.error('Error deleting barcode:', error);
            toast.error('Error deleting barcode','error');

        }
    };

    // Check box
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setBarCodeInputData({...barCodeInputData,isActive:event.checked});
        setIsChecked(event.target.checked);
    };

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Barcodes</Card.Title>
                <Button variant="primary" onClick={() => handleshowBarcodeModal("Add Barcode")}>
                    <i className="bi bi-plus-square me-2"></i> Add Barcode
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="barcodeDataTable">
                <thead className="table-light">
                    <tr>
                        <th>No</th>
                        <th>QR/Barcode Code</th>
                        <th>Type</th>
                        <th>Action</th>
                    </tr>
                </thead>
            </Table>

            {/* Barcode Modal */}
            <Modal centered size="lg" show={showBarcodeModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="pb-0">
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="form-space">
                                <Form.Label>QR/Barcode Code</Form.Label>
                                <Form.Control 
                                   type="text"
                                   placeholder="Enter code..." 
                                   name="code"
                                   value={barCodeInputData.code}
                                   onChange={(e) => setBarCodeInputData({...barCodeInputData, code: e.target.value})} 
                                   
                                   />
                            </Form.Group>
                            <Form.Group className="form-space">
                                <Form.Label>Select QR/Barcode Type</Form.Label>
                                <Form.Select 
                                    aria-label="Default select example"
                                    value={barCodeInputData.code_type}
                                    onChange={e => setBarCodeInputData({...barCodeInputData, code_type: e.target.value})} 
                                >
                                    <option>Select QR/Barcode Type</option>
                                    {Array.isArray(barCodeData) && 
                                    [...new Set(barCodeData.map(barCode => barCode.code_type))].map((codeType, index) => (
                                        <option key={index} value={codeType}>{codeType}</option>
                                    ))
                                    }

                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Form>
                            <Form.Check 
                               type="checkbox"
                                id="default-checkbox" 
                                label="Is Active"
                                 checked={barCodeInputData.isActive} 
                                 onChange={handleCheckboxChange} 
                                />
                        </Form>
                    </Row>
                </Modal.Body>
                <Modal.Footer className="mt-1">
                    <Button variant="primary" onClick={handleSaveBarcode}>Save</Button>
                    <Button variant="light" onClick={handleCloseModal}>Close</Button>
                </Modal.Footer>
            </Modal>

            {/* Delete Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setshowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setshowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handeleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
