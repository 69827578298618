import React, { useState } from 'react';
import Footer from "../layout/Footer";
import { Container, Row, Col, Card, Nav, Tab } from "react-bootstrap";
import PageTitle from '../layout/PageTitle';
import VehicleMapping from './VehicleMapping';
import VehicleAssignment from './VehicleAssignment';
import DestinationAssignment from './DestinationAssignment';
import DestinationMapping from './DestinationMapping';

export default function MappingAssignment() {
    const [VehicleMappingData, setVehicleMappingData] = useState([
        { name: 'Alpha Truck 01', type: 'Truck', drivername: 'John Doe'},
        { name: 'Beta Van 02', type: 'Van', drivername: 'Jane Smith'},       
    ]);

    const [VehicleAssignmentData, setVehicleAssignmentData] = useState([
        { name: 'Alpha Truck 01', type: 'V001', drivername: 'D001', date: '2022-01-01'},
        { name: 'Beta Van 02', type: 'V002', drivername: 'D002', date: '2022-01-01'},       
    ]);

    const [DestinationMappingData, setDestinationMappingData] = useState([
        { name: 'Alpha Truck 01', type: 'Truck', drivername: 'John Doe'},
        { name: 'Beta Van 02', type: 'Van', drivername: 'Jane Smith'},       
    ]);
    
    const [DestinationAssignmentData, setDestinationAssignmentData] = useState([
        { name: 'V001-ABX', type: 'Destination A', date: '2022-01-01'},
        { name: 'V002-CDY', type: 'Destination B',  date: '2022-01-01'},       
    ]);



    // Navtab data
    const tabData = [
        { key: 'VehicleMapping', label: 'Vehicle Mapping', component: <VehicleMapping /> },
        // { key: 'supervisors', label: 'Supervisors', component: <Supervisors supervisorData={supervisorData} /> },
        { key: 'VehicleAssignment', label: 'Vehicle Assignment', component: <VehicleAssignment /> },
        { key: 'DestinationMapping', label: 'Destination Mapping', component: <DestinationMapping /> },
        { key: 'DestinationAssignment', label: 'Destination Assignment', component: <DestinationAssignment /> },
        
    ];
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Management" />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="VehicleMapping">
                                            <Row>
                                                <Col md={12}>
                                                    <Nav justify variant="pills" className="custom-navtab gap-2">
                                                        {tabData.map(({ key, label }) => (
                                                            <Nav.Item key={key}>
                                                                <Nav.Link eventKey={key}>{label}</Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                                <Col md={12}>
                                                    <Tab.Content>
                                                        {tabData.map(({ key, component }) => (
                                                            <Tab.Pane eventKey={key} key={key}>
                                                                {component}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
