import React, { useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm-light.png";
import { Link, useNavigate } from "react-router-dom";
import { getFromAPI, postToAPI, putToAPI, deleteFromAPI } from './Utils/utils.jsx'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";


export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [inputData, setInputData] = useState({
    emailOrMobile: "",
    password: "",
  });

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogin = async () => {
    try {

      const isEmail = /\S+@\S+\.\S+/.test(inputData.emailOrMobile);
      const isMobileWithCountryCode = /^\+\d{1,3}\d{10}$/.test(inputData.emailOrMobile); // Updated regex
  
      if (!isEmail && !isMobileWithCountryCode) {
        toast.error("Please enter a valid Email address or a Mobile Number with Country code.",'error');
        return;
      }
      // API request to login
      const response = await postToAPI("login/", {
        emailOrMobile : inputData.emailOrMobile,
        password : inputData.password
      });

      if (response.status === true) {
        localStorage.setItem('userType', response.data.userType);
        localStorage.setItem('userId',response.data.userId)
        // Retrieve and log data from localStorage
        const storedUserType = localStorage.getItem('userType');
        const storeduserid = localStorage.getItem('userId');

        navigate("/dashboard");
      } else {
        toast.error("Invalid email or password",'error');
      }
    } catch (error) {
      toast.error("Invalid Email/Mobile Number or password",'error');
    }
  };

  const handleChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };
  // Form validation
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault(); // Prevent page reload
      handleLogin(); // Trigger login after validation
    }
    setValidated(true);
  };

  return (
    <>
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={5} xxl={4}>
              <Card>
                <div className="auth-logo">
                  <img src={LogoSm} alt="default" className="png-img-shadow rounded-circle" />
                </div>
                <Card.Body className="pt-5 mt-4">
                  {/* card text */}
                  <Card.Title className="text-center mt-3 mb-0">Welcome Back!</Card.Title>
                  <p className="text-center text-muted">Please enter your details to login.</p>


                  {/* Form implementation */}
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="form-space" controlId="formBasicEmail">
                      <Form.Label>Email Address or Mobile Number <span className="text-danger">*</span></Form.Label>
                      <Form.Control 
                        type="emailOrMobile" 
                        placeholder="Enter email or number..."
                         name="emailOrMobile"
                         value={inputData.emailOrMobile}
                         onChange={handleChange}
                        required />
                      <Form.Control.Feedback type="invalid">Please enter a valid email or mobile number.</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="form-space" controlId="formBasicPassword">
                      <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                      <div className="input-icon-content">
                        <i className={`bi ${showPassword ? "bi-eye" : "bi-eye-slash"
                          } input-icon`}
                          onMouseDown={() => setShowPassword(true)}
                          onMouseUp={() => setShowPassword(false)}
                          onMouseOut={() => setShowPassword(false)}
                          onTouchStart={() => setShowPassword(true)}
                          onTouchEnd={() => setShowPassword(false)}
                        ></i>
                        <Form.Control 
                           type={showPassword ? "text" : "password"}
                            placeholder="Enter Password..." 
                            name="password" 
                            onChange={handleChange} 
                            value={inputData.password}
                            required />
                        <Form.Control.Feedback type="invalid">Please enter a valid password.</Form.Control.Feedback>
                      </div>
                    </Form.Group>

                    <div className="d-flex align-items-center justify-content-between form-space">
                      <Form.Group controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Remember me" />
                      </Form.Group>
                      <Link className="text-muted" to="/forgot">Forgot Password?</Link>
                    </div>
                    
                    <Button type="submit" className="btn-primary w-100" onClick={handleLogin}><i className="bi bi-box-arrow-in-right me-2"></i>Login</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
