import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Modal, Table } from "react-bootstrap";
import PageTitle from '../layout/PageTitle';
import Footer from "../layout/Footer";

//Data-table
import JSZip from "jszip";
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import 'datatables.net-dt/css/jquery.dataTables.css';
import ComplaintsListTableData from "./dashboard_data.json";
import 'jquery-ui-dist/jquery-ui.css'; // jQuery UI CSS for date picker
import 'jquery-ui-dist/jquery-ui.js';
import {getFromAPI,putToAPI,postToAPI,deleteFromAPI} from "../Utils/utils"

export default function Dashboard() {

  // View Complaints modal
  const [vehicleModal, setVehicleModal] = useState(false);
  const vehicleModalClose = () => setVehicleModal(false);
  const vehicleModalShow = () => setVehicleModal(true);
  const [dashboardData,setDashBoardData] = useState([]);
  const [qrData, setQrData] = useState({}); // To store QR data for each vehicle
  const [ComplaintsListData] = useState(ComplaintsListTableData);
  const [selectedFilter, setSelectedFilter] = useState(null);

  // TODO: Create Complaint object with status using API data.
  const [ComplaintsStatusData] = useState(ComplaintsListTableData);

  const fetchDashBoardData = async() => {
    // API request to fetch dashboard data
     const response = await getFromAPI("vehiclemapping/");
      if (response.status) {
        setDashBoardData(response.vehicleMappingData)
       //setDashBoardData(response.data);
     }
  };

// Fetch assigned QR codes for each vehicle and calculate success percentage
const getCollection = async (vehicle_id, destinations) => {
  try {
    const assignedQRResponse = await getFromAPI(`getassignedQR/?vehicle_id=${vehicle_id}`);

    if (assignedQRResponse.destinations && assignedQRResponse.destinations.length > 0) {
      const totalQRCount = assignedQRResponse.destinations.length;

      // Map the QR codes from the 'destinations' array
      const qrCodeIds = assignedQRResponse.destinations.map(destination => destination.qr_code.id);

      // Pass the QR codes to the API call
      const scanLogsResponse = await getFromAPI(`QRScanLog/?qrCodeId=${qrCodeIds.join(',')}`);

      if (scanLogsResponse) {
        const successfulScans = scanLogsResponse.filter(log => log.issuccessful === true).length;
        const successPercentage = (successfulScans / totalQRCount) * 100;
        return successPercentage.toFixed(2);
      }
    }
    return 0; // Return 0 if no data or error
  } catch (error) {
    console.error('Error fetching data:', error);
    return 0; // Return 0 in case of an error
  }
};

  useEffect(() => {
    fetchDashBoardData();
  }, []);

  useEffect(() => {
    if (dashboardData.length > 0) {
      dashboardData.forEach((data) => {
        getCollection(data.vehicle.id);
      });
    }
  }, [dashboardData]);


  DataTable.Buttons.jszip(JSZip);
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  useEffect(() => {
    if (dashboardData && dashboardData.length > 0) {
      const fetchAllCollections = async () => {
        const dataWithCollection = await Promise.all(
          dashboardData.map(async (data, i) => {
            const collectionPoints = await getCollection(data.vehicle.id);
            // Determine the status based on collectionPoints
            let statusText = '';
            let barColor = '';
            let barPercentage = '0%';
            let statusBar;
  
            if (collectionPoints === '0') {
              statusText = 'Pending';
              barColor = 'danger'; // Red for pending
              statusBar= "danger";
            } else if (collectionPoints > '0' && collectionPoints < '100.00') {
              statusText = 'In Progress';
              barColor = 'warning'; // Yellow for in progress
              statusBar= "warning";
              barPercentage = `${collectionPoints}%`; // Set the progress bar width
            } else if (collectionPoints === '100.00') {
              statusText = 'Complete';
              barColor = 'primary'; // Green for complete
              barPercentage = '100%'; // Full width progress bar
              statusBar= "success";
            }
  
            return [
              i + 1,
              data.vehicle.registrationNumber,
              data.driver.FirstName,
              collectionPoints, // Collection Points Percentage
              statusText,
              statusBar,
              barColor, // Pass the bar color
              barPercentage // Pass the bar percentage
            ];
          })
        );
  
        if (!$.fn.dataTable.isDataTable("#maindatatable")) {
          $("#maindatatable").DataTable({
            dom: '<"date-filter" <"from-date"> <"to-date">>B<"top"lf>rtip',
            buttons: [
              {
                extend: 'excel',
                className: 'btn btn-export btn-md btn-secondary',
                text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
              },
              {
                extend: 'pdf',
                className: 'btn btn-export btn-md btn-secondary',
                text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to PDF'
              },
              {
                extend: 'print',
                className: 'btn btn-export btn-md btn-secondary',
                text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
              }
            ],
            data: dataWithCollection.filter(item => {
              if (!selectedFilter) return true; // No filter applied
              // Filter based on selectedFilter criteria
              return item[4] === selectedFilter; // Example: filter by statusText
            }),            columns: [
              { title: "No." },
              { title: "Vehicle Number" },
              { title: "Driver Name" },
              { title: "Collection Points %" },
              {
                title: "Completed Collections",
                render: function (data, type, row) {
                  const barColor = row[6]; // Get the bar color
                  const barPercentage = row[7]; // Get the progress bar percentage
                  console.log("BarPercentage", barPercentage)
                  return `
                    <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="height: 10px">
                      <div class="progress-bar bg-${barColor}" style="width: ${barPercentage}"></div>
                    </div>
                  `;
                }
              },
              {
                title: "Status",
                render: function (data, type, row) {
                  const statusText = row[4]; // Get the status text
                  const statusBar = row[5]; // Get the status color
                  return `
                    <span class="badge badge-soft-${statusBar}">${statusText}</span>
                  `;
                }
              },
              {
                title: "Action",
                render: function (data, type, row) {
                  return `
                    <button class="btn btn-light btn-sm view-complaint" data-compl-id="${row[1]}">View Map</button>
                  `;
                }
              }
            ],
            autoWidth: false,
            columnDefs: [
              {
                targets: -1,
                orderable: false
              }
            ],
            language: {
              search: "",
              searchPlaceholder: "Search...",
              paginate: {
                previous: "«",
                next: "»"
              }
            }
          });
  
          // Event delegation to handle view-complaint button click
          document.querySelector('#maindatatable tbody').addEventListener('click', function (event) {
            if (event.target && event.target.classList.contains('view-complaint')) {
              const compl_id = event.target.getAttribute('data-compl-id');
              // TODO: Handle modal show based on complaint ID
              vehicleModalShow();
            }
          });
  
          // Initialize date filters
          $("div.from-date").html(`
            <label>Start Date:
              <div class="date-input-with-icon">
                <input type="text" id="min-date" placeholder="YYYY-MM-DD" class="datepicker form-control" />
                <i class="bi bi-calendar date-input-icon"></i>
              </div>
            </label>
          `);
          $("div.to-date").html(`
            <label>End Date:
              <div class="date-input-with-icon">
                <input type="text" id="max-date" placeholder="YYYY-MM-DD" class="datepicker form-control" />
                <i class="bi bi-calendar date-input-icon"></i>
              </div>
            </label>
          `);
  
          // Initialize jQuery UI date pickers
          $(".datepicker").datepicker({
            dateFormat: 'yy-mm-dd',
            changeMonth: true,
            changeYear: true,
            onSelect: function () {
              $("#maindatatable").DataTable().draw(); // Redraw the DataTable when the date is selected
            }
          });
  
          // Date Range Filter
          $.fn.dataTable.ext.search.push(function (settings, data) {
            const min = new Date($('#min-date').val()).getTime();
            const max = new Date($('#max-date').val()).getTime();
            const date = new Date(data[2]).getTime(); // Date column (index 2)
  
            return (
              (isNaN(min) && isNaN(max)) ||
              (isNaN(min) && date <= max) ||
              (min <= date && isNaN(max)) ||
              (min <= date && date <= max)
            );
          });
  
          // Add event listeners for date range inputs
          document.querySelectorAll('#min-date, #max-date').forEach((el) => {
            el.addEventListener('change', () => $("#maindatatable").DataTable().draw());
          });
        }
      };
  
      fetchAllCollections();
    }
  }, [dashboardData, qrData]); // Depend on both dashboardData and qrData
  
  const handleCardClick = (status) => {
    setSelectedFilter(status);
  };
  
  const [fullscreen, setFullscreen] = useState(true);

  return (
    <>
      <div className="page-wrapper">
        <div className="page-content">
          <PageTitle breadcrumbLast="Dashboard" />

          <Container>
          <Row xs={1} sm={2} xl={4} className="justify-content-center">
              {ComplaintsStatusData.ComplaintsData.map((data, i) =>
                <Col className="mb-4" key={i}>
                  <Card
                    className={`mb-0 h-100 widgets-card ${data.complaintsColor} ${i === 0 ? 'widgets-active' : ''}`}
                    onClick={() => handleCardClick(data.complaintsTitle)} // Example filter based on complaintsTitle
                  >
                    <Card.Body>
                      <div className="d-flex align-items-center">
                        <div className="avatar avatar-xl">
                          <i className={`bi ${data.complaintsIcon}`}></i>
                        </div>
                        <div className="ms-3">
                          <h2 className="widgets-title fw-bold c-mb-2">{data.complaintsTitle}</h2>
                          <p className="mb-0 text-muted">{data.complaintsSubTitle}</p>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Card.Title>Vehicle Collection Status</Card.Title>                        
                    <Table responsive bordered className="mb-0 table-nowrap" id="maindatatable">
                    <thead className="table-light">
                        <tr>
                            <th>No.</th>
                            <th>Vehicle Number</th>
                            <th>Driver Name</th>
                            <th>Collection Points %</th>
                            <th>Completed Collections</th>
                            <th>Status</th>
                            <th>Action</th>
                            
                        </tr>
                    </thead>
                </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>

      {/* Complaint Details */}
      <Modal centered fullscreen={fullscreen} show={vehicleModal} onHide={vehicleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>View Map</Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-hidden">
          <iframe
            title="Google Map of New York City"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387191.03599933005!2d-74.30932003266689!3d40.697539975415474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1725865186799!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
}
