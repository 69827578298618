import React, { useState } from "react";
import { Button, Card, Container, Form, Row, Col } from "react-bootstrap";
import LogoSm from "../images/logo-sm-light.png";
import { useNavigate } from "react-router-dom";
import { postToAPI } from './Utils/utils.jsx'; // Import utility functions
import { toast, ToastContainer } from "react-toastify";

export default function SetNewPassword() {
  const storedData = JSON.parse(localStorage.getItem('loginData'));
  const [passwordData, setPasswordData] = useState({
    new_password: "",
    confirm_password: "", 
    user_identifier: "",
  });
  const navigate = useNavigate();

  const handlePasswordReset = async () => {
    if (passwordData.new_password !== passwordData.confirm_password) {
      toast.error("Passwords and Confirm Password  not match.",'error');
      return;
    }
    // Placeholder for password reset API call
    try {
      const response = await postToAPI("reset_password/", {
        new_password: passwordData.new_password,
        user_identifier : storedData
      });
      if (response.status) {
        toast.success("Password reset successful.",'success');
        navigate("/"); // Navigate to login page after successful password reset
      } else {
        toast.error("Error during password reset:", "error");
      }
    } catch (error) {
      toast.error("Error during password reset:", "error");
     // alert(error.message);
    }
  };
  const handleChange = (e) => {
    setPasswordData({ ...passwordData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePasswordReset(); // Trigger password reset function
  };

  return (
    <>
      <div className="auth-bg py-4 py-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={5} xxl={4}>
              <Card>
              <div className="auth-logo">
                  <img src={LogoSm} alt="default" className="png-img-shadow rounded-circle" />
                </div>
                <Card.Body className="pt-5 mt-4">

                  {/* card text */}
                  <Card.Title className="text-center mt-3 mb-0">Set New Password</Card.Title>
                  <p className="text-center text-muted">Must be at least 8 characters.</p>

                  {/* Form implementation */}
                  <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formNewPassword" className="form-space">
                      <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Enter new password"
                        name="new_password"
                        value={passwordData.new_password}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid password.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formConfirmPassword" className="form-space">
                      <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control
                        type="password"
                        placeholder="Confirm new password"
                        name="confirm_password"
                        value={passwordData.confirm_password}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please confirm your password.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button type="submit" className="btn-primary w-100 mt-2">Set New Password</Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}
