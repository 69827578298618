import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footer() {
    return (
        <>
            <div className="footer border-top">
                <Container>
                    <Row>
                        <Col>
                            <div className="footer-content text-center">
                                <p className='mb-0 fs-16 text-muted'>© {new Date().getFullYear()} D2D Collection App. All rights reserved.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}
