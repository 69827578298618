import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from "../layout/Footer";
import { Container, Row, Col, Card, Nav, Tab } from "react-bootstrap";
import Vehicles from './Vehicles';
import Supervisors from './Supervisors';
import Drivers from './Drivers';
import Wards from './Wards';
import Zones from './Zones';
import Destinations from './Destinations';
import Barcodes from './Barcodes';
import { getFromAPI } from "../Utils/utils";

export default function Management() {
    // State initialization
    const [vehicleData, setVehicleData] = useState([]);
    const [supervisorData, setSupervisorData] = useState([]);
    const [driverData, setDriverData] = useState([]);
    const [wardData, setWardData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [destinationsData, setdestinationsData] = useState([]);
    const [barcodeData, setBarcodeData] = useState([
        { id: 'QR001', brcdcode:'QR001',  brcdtype:'Vehicle' },
        { id: 'QR002', brcdcode:'QR002',  brcdtype:'Destination' },
        { id: 'QR003', brcdcode:'QR003',  brcdtype:'Vehicle' },
        { id: 'QR004', brcdcode:'QR004',  brcdtype:'Destination' },
        { id: 'QR005', brcdcode:'QR005',  brcdtype:'Vehicle' },
    ]);

    const [userData, setuserData] = useState([]);
   



    useEffect(() => {
        const fetchVehicleData = async () => {
          try {
            const response = await getFromAPI("vehicle/");
            setVehicleData(response);
          } catch (error) {
            console.error("Error fetching vehicle:", error);
          }
        };
    
        fetchVehicleData();
      }, []);

      useEffect(() => {
        const fetchDestinationData = async () => {
          try {
            const response = await getFromAPI("destination/");
            setdestinationsData(response);
          } catch (error) {
            console.error("Error fetching DestData:", error);
          }
        };
    
        fetchDestinationData();
      }, []);

      useEffect(() => {
        const fetchdriverData = async () => {
          try {
            const response = await getFromAPI("driver/");
            setDriverData(response.drivers);
          } catch (error) {
            console.error("Error fetching driver:", error);
          }
        };
    
        fetchdriverData();
      }, []);

      useEffect(() => {
      const fetchSupervisorData = async () => {
        try {
            const response = await getFromAPI('supervisor/');
            setSupervisorData(response);
        } catch (error) {
            console.error('Error fetching supervisor data:', error);
        }
    };

    fetchSupervisorData();
}, []);



useEffect(() => {
  const fetchZones = async () => {
    try {
        const response = await getFromAPI('zone/');
        setZoneData(response);
    } catch (error) {
        console.error('Error fetching zone data:', error);
    }
};

fetchZones();
}, []);

 useState(() => {
    const fetchWardData = async () => {
      try {
        const response = await getFromAPI('ward/');
        setWardData(response);
      } catch (error) {
        console.error('Error fetching ward data:', error);
      }
    };
    fetchWardData();
  }, []);



    // Navtab data
    const tabData = [
        { key: 'vehicles', label: 'Vehicles', component: <Vehicles vehicleData={vehicleData} /> },
        { key: 'supervisors', label: 'Supervisors', component: <Supervisors supervisorData={supervisorData} /> },
        { key: 'drivers', label: 'Drivers', component: <Drivers driverData={driverData}/> },
        { key: 'wards', label: 'Wards', component: <Wards wardData={wardData} /> },
        { key: 'zones', label: 'Zones', component: <Zones zoneData={zoneData} /> },
        { key: 'destinations', label: 'Destinations', component: <Destinations destinationsData={destinationsData} /> },
        { key: 'barcodes', label: 'Barcodes', component: <Barcodes barcodeData={barcodeData} /> }
    ];

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Management" />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="vehicles">
                                            <Row>
                                                <Col md={12}>
                                                    <Nav justify variant="pills" className="custom-navtab gap-2">
                                                        {tabData.map(({ key, label }) => (
                                                            <Nav.Item key={key}>
                                                                <Nav.Link eventKey={key}>{label}</Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                                <Col md={12}>
                                                    <Tab.Content>
                                                        {tabData.map(({ key, component }) => (
                                                            <Tab.Pane eventKey={key} key={key}>
                                                                {component}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}
